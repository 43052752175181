.float-left {
  float: left;
}

.w30p{
  width: 30%;
}

.clear-both{
  clear: both;
}